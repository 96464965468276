/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useRef, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as Hamburger } from '../../images/icons/menu.svg';
import { ReactComponent as Profile } from '../../images/icons/profile.svg';
import { ReactComponent as Cart } from '../../images/icons/cart.svg';
import { ReactComponent as Wishlist } from '../../images/icons/wishlist.svg';
import { ReactComponent as Groceries } from '../../images/icons/basket.svg';

import { CONFIG_SHAPE } from '../../state/config/config';
import constructURL from '../../utils/constructURL';

import Avatar from '../Avatar/Avatar';
import styles from './Header.module.scss';
import useMediaQuery from '../../utils/useMediaQuery';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import { BLOGS_LIST_PAGE, CART, HEADER_DAILIES } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { DAILY_BAR } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import { getPagePath, getPopupOnPagePath } from '../../utils/appRoutes';
import {
  ARD_BLOGS,
  ARD_POPUP_CART,
  ARD_POPUP_DAILIES,
  ARD_POPUP_MENU,
  ARD_POPUP_PROFILE,
  ARD_POPUP_PROFILE_WISHLIST,
} from '../../utils/appRoutes.definitions';
import { usePopupOpened } from '../../utils/usePopupOpened';
import useWishlist from '../../utils/useWishlist';

const ProfilePopup = loadable(() => import('../ProfilePopup/ProfilePopup'));
const BookmarkPopup = loadable(() => import('../BookmarkPopup/BookmarkPopup'));
const NewsletterSettingsDrawer = loadable(() => import('../NewsletterSettingsDrawer/NewsletterSettingsDrawer'));
const PersonalInformationDrawer = loadable(() => import('../PersonalInformationDrawer/PersonalInformationDrawer'));
const RecentlyViewedPopup = loadable(() => import('../RecentlyViewedPopup/RecentlyViewedPopup'));
const WishlistPopup = loadable(() => import('../WishlistPopup/WishlistPopup'));
const CartPopup = loadable(() => import('../CartPopup/CartPopup'));
const MenuDrawer = loadable(() => import('../MenuDrawer/MenuDrawer'));

const Header = React.memo(({ classList = {}, dailies, config, profile }) => {
  const location = useLocation();
  const { push } = useHistory();
  const header = useRef(null);
  const intl = useIntl();
  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery();
  const { root = '' } = classList;
  const openCarts = useSelector(({ cart }) => cart.openCarts);
  const { wishlistProducts } = useWishlist();

  const [
    profilePopupOpened,
    wishlistPopupOpened,
    cartPopupOpened,
    dailiesPopupOpened,
    menuPopupOpened,
  ] = usePopupOpened(ARD_POPUP_PROFILE, ARD_POPUP_PROFILE_WISHLIST, ARD_POPUP_CART, ARD_POPUP_DAILIES, ARD_POPUP_MENU);

  const productsInCart = openCarts?.reduce((acc, cart) => {
    return acc + cart.products.reduce((amount, product) => amount + product.quantity, 0);
  }, 0);

  const profilePopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_PROFILE));
  const wishlistPopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_PROFILE_WISHLIST));
  const cartPopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_CART));
  const pageUrlNoPopups = constructURL(location.pathname, {
    ignoreExistingHash: true,
  });

  useEffect(() => {
    const handler = () => {
      if (
        //todo: handle popup closing the other way
        (profilePopupOpened || wishlistPopupOpened || cartPopupOpened) &&
        isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE)
      ) {
        push({ ...location, hash: '' });
      }
    };
    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [
    location,
    push,
    BREAKPOINTS.EXTRA_LARGE,
    isGreaterOrEqualTo,
    profilePopupOpened,
    wishlistPopupOpened,
    cartPopupOpened,
  ]);

  const hasDailies = dailies && dailies.length > 0;

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <header className={classnames(styles.header, root)} onClick={handleClick}>
        <div className={classnames(styles.headerContainer, 'container')}>
          <div className="row">
            <div className={classnames(styles.hamburger, 'col-3-xs')}>
              <Link aria-label={intl.formatMessage({ id: 'header.menu' })} to={getPopupOnPagePath(ARD_POPUP_MENU)}>
                <Hamburger />
              </Link>
            </div>
            <div className={classnames('col-6-xs col-2-xxl')}>
              <Link aria-label={config.shopName || 'Webshop'} to="/" className={classnames(styles.logo)}>
                <img
                  alt={config.shopName || 'Webshop'}
                  loading="lazy"
                  title={config.shopName || 'Webshop'}
                  src={config.theme.logos.main_logo}
                  width={110}
                  height={33}
                />
              </Link>
            </div>
            <div className={classnames(styles.pageLinks, 'col-8-xxl')}>
              <SwitchableFeature feature={HEADER_DAILIES}>
                {/* <Link
                  aria-label={intl.formatMessage({ id: 'header.shopping' })}
                  to={constructURL(`${config.cmsSlugs.search}/`)}
                  className={classnames(styles.pageLinkWhite)}
                >
                  <Shopping />
                  <FormattedMessage id="header.shopping" defaultMessage="Shopping" />
                </Link> */}
                <SwitchableFeature
                  dependencies={[
                    [DAILY_BAR, true],
                    [CART, false],
                  ]}
                >
                  <Link
                    aria-label={intl.formatMessage({ id: 'common.dailies' })}
                    to={getPopupOnPagePath(ARD_POPUP_DAILIES)}
                    className={classnames({ [styles.active]: dailiesPopupOpened })}
                  >
                    <Groceries />
                    <FormattedMessage id="common.dailies" defaultMessage="Daily" />
                  </Link>
                </SwitchableFeature>
              </SwitchableFeature>
              <SwitchableFeature feature={BLOGS_LIST_PAGE}>
                <Link className={styles.largeLink} to={getPagePath(ARD_BLOGS)}>
                  <FormattedMessage id="common.blogs" defaultMessage="Blogs" />
                </Link>
              </SwitchableFeature>
            </div>
            <div
              className={classnames(styles.userIcons, 'col-3-xs', 'col-2-xxl', {
                'offset-9-xs offset-10-xxl': !hasDailies,
              })}
            >
              <div className={classnames(styles.menu)} ref={header}>
                <Link
                  data-testid="profile-dropdown"
                  aria-label={config.cmsSlugs.profile}
                  to={profilePopupOpened ? pageUrlNoPopups : profilePopupUrl}
                  className={classnames({ [styles.loggedIn]: profile, [styles.isOpen]: profilePopupOpened })}
                >
                  {!profile && (
                    <>
                      <Profile />
                      <p>
                        <FormattedMessage id="common.logIn" defaultMessage="Log in" />
                      </p>
                    </>
                  )}
                  {profile && <Avatar src={profile.picture} salutation={profile.salutation} />}
                </Link>
                <Link
                  aria-label={intl.formatMessage({ id: 'profile.wishlist' })}
                  to={wishlistPopupOpened ? pageUrlNoPopups : wishlistPopupUrl}
                  className={classnames(styles.wishlistIcon, styles.badgeCounterCountainer, {
                    [styles.isOpen]: wishlistPopupOpened,
                    [styles.hasItems]: wishlistProducts?.length,
                  })}
                >
                  {!!wishlistProducts?.length && <span className={styles.counterBadge}>{wishlistProducts.length}</span>}
                  <Wishlist />
                  <p>
                    <FormattedMessage id="common.wishlist" defaultMessage="Wishlist" />
                  </p>
                </Link>
                <SwitchableFeature feature={CART}>
                  <Link
                    data-testid="cart-popup"
                    aria-label={config.cmsSlugs.profile}
                    to={cartPopupOpened ? pageUrlNoPopups : cartPopupUrl}
                    className={classnames(styles.badgeCounterCountainer, {
                      [styles.isOpen]: cartPopupOpened,
                      [styles.hasItems]: true,
                    })}
                  >
                    {!!productsInCart && <span className={styles.counterBadge}>{productsInCart}</span>}
                    <Cart />
                    <p>
                      <FormattedMessage id="common.cart" defaultMessage="Cart" />
                    </p>
                  </Link>
                </SwitchableFeature>
              </div>
            </div>
            <ProfilePopup shouldBeOpen={profilePopupOpened} />
            <WishlistPopup shouldBeOpen={wishlistPopupOpened} />
            <SwitchableFeature feature={CART}>
              <CartPopup shouldBeOpen={cartPopupOpened} />
            </SwitchableFeature>
          </div>
        </div>
      </header>
      {!!menuPopupOpened && <MenuDrawer />}
      {profile && (
        <>
          <RecentlyViewedPopup productIds={profile.recentlyViewedProducts} />
          <BookmarkPopup blogIds={profile.bookmarkedBlogs} />
          <PersonalInformationDrawer />
          <NewsletterSettingsDrawer />
        </>
      )}
    </>
  );
});

Header.displayName = 'Header';
Header.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
  config: CONFIG_SHAPE,
};

export const mapStateToProps = ({ config, dailies, profile, router }) => ({
  config,
  dailies,
  profile,
  router,
});
export default connect(mapStateToProps)(Header);
